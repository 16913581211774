import { useRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Constants } from 'utils/constants';
import FallBackLoader from 'components/fall-back-loader';

const Dashboard = lazy(() => import('pages/dashboard'));
const CodeSubmission = lazy(() => import('pages/code-submission'));
const PickYourDorito = lazy(() => import('pages/pick-your-dorito'));
const PrizeClaim = lazy(() => import('pages/prize-claim'));
const Quiz = lazy(() => import('pages/quiz'));
const TermsAndConditions = lazy(() => import('pages/terms-and-conditions'));
const Oops = lazy(() => import('pages/oops'));
const DummyPage = lazy(() => import('pages/dummy-page'));

const AppRoutes = () => {
  // CONSTANTS:
  const { routeNames } = Constants();

  const routes = useRoutes([
    {
      path: routeNames.dashboard,
      element: <Dashboard />,
    },
    {
      path: routeNames.codeSubmission,
      element: <CodeSubmission />,
    },
    {
      path: routeNames.quiz,
      element: <Quiz />,
    },
    {
      path: routeNames.pickYourDorito,
      element: <PickYourDorito />,
    },
    {
      path: routeNames.prizeClaim,
      element: <PrizeClaim />,
    },
    {
      path: routeNames.oops,
      element: <Oops />,
    },
    {
      path: routeNames.termsAndConditions,
      element: <TermsAndConditions />,
    },
    {
      path: routeNames.dummypage,
      element: <DummyPage />,
    },

    // {
    //   path: routeNames.adminLogin,
    //   element: <AdminLogin />,
    // },

    // {
    //   path: '',
    //   element: <AdminLayout />,
    //   children: [
    //     {
    //       path: routeNames.adminDashboard,
    //       element: <AdminDashBoard />,
    //     },
    //     {
    //       path: routeNames.participants,
    //       element: <Participants />,
    //     },
    //     {
    //       path: routeNames.winners,
    //       element: <Winners />,
    //     },
    //     {
    //       path: routeNames.addQAndA,
    //       element: <AddQandA />,
    //     },
    //     {
    //       path: routeNames.listOfQAndA,
    //       element: <ListOfQandA />,
    //     },
    //   ],
    // },
  ]);

  return <Suspense fallback={<FallBackLoader />}>{routes}</Suspense>;
};

export { AppRoutes };
