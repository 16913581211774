import { listOfQandA, participantsIcon, winnersIcon } from 'resources/images';

const Constants = () => {
  // ROUTE NAMES:
  const routeNames = {
    dashboard: '/',
    codeSubmission: '/codesubmission',
    pickYourDorito: '/pickyourdorito',
    prizeClaim: '/prizeclaim',
    quiz: '/quiz',
    oops: '/oops',
    termsAndConditions: '/terms&conditions',
    dummypage: '/tavvlingsvillkor',

    // ADMIN DASHBOARD:

    adminLogin: '/adminlogin',
    adminDashboard: '/admindashboard',
    participants: '/participants',
    winners: '/winners',
    addQAndA: '/addQandA',
    listOfQAndA: '/listOfQandA',
  };

  // ADMIN DASHBOARD STATISTICS DATA

  const adminDashboardStatisticsData = [
    {
      icon: participantsIcon,
      label: 'Participants',
      count: '0',
      route: routeNames.participants,
    },
    {
      icon: winnersIcon,
      label: 'Winners',
      count: '0',
      route: routeNames.winners,
    },
    {
      icon: listOfQandA,
      label: 'List Of Q & A',
      count: '0',
      route: routeNames.listOfQAndA,
    },
  ];

  // LIST OF QandA COLUMNS

  const listOfQandAColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'question', headerName: 'Question' },
    { field: 'answer', headerName: 'Answer' },
  ];

  // PARTICIPANTS COLUMNS

  const participantsColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'name', headerName: 'Name' },
    { field: 'batchCode', headerName: 'Batch Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'address', headerName: 'Address' },
  ];

  // WINNER COLUMNS
  const winnerColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'name', headerName: 'Name' },
    { field: 'batchCode', headerName: 'Batch Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'address', headerName: 'Address' },
  ];

  return {
    routeNames,
    adminDashboardStatisticsData,
    listOfQandAColumns,
    participantsColumns,
    winnerColumns,
  };
};

export { Constants };
