// User ENDPOINTS

export const userRegister = '/user/save';
export const getUserData = '/user/{user_id}';
export const getQuizData = '/questions/random/{user_id}';
export const checkAnswer = '/questions/check-answer';
export const checkWinnerOrNot = '/user/submit-code';
export const getSpinCodes = '/user/spin-codes';

// ADMIN ENDPOINTS

export const adminLogin = '/admin/login';

// QUESTIONS
export const createQuestion = '/questions/save';
export const getAllQuestions = '/questions/all';
export const deleteQuestion = '/questions/delete/{id}';
export const editQuestion = '/questions/update';

// STATISTICS
export const getStatistics = '/admin/get-statistics';

// PARTICIPANTS
export const getAllParticipants = '/admin/get-all-users';

// WINNERS
export const getAllWinners = '/admin/get-all-winners';
