import axiosInstance from 'utils/axios';
import * as endPoints from 'networking/endpoints';

// ADMIN LOGIN API
export const adminLoginApi = async (data) =>
  Promise.resolve(axiosInstance.post(endPoints.adminLogin, data));

// STATISTICS:

export const getStatisticsApi = async () =>
  Promise.resolve(axiosInstance.get(endPoints.getStatistics));

// PARTICIPANTS

export const getAllParticipantsApi = async (page = 1) =>
  Promise.resolve(
    axiosInstance.get(`${endPoints.getAllParticipants}?page=${page}`)
  );

// WINNERS

export const getAllWinnersApi = async (page = 1) =>
  Promise.resolve(axiosInstance.get(`${endPoints.getAllWinners}?page=${page}`));

// QUESTIONS

export const createQuestionApi = async (data) =>
  Promise.resolve(axiosInstance.post(endPoints.createQuestion, data));

export const getAllQuestionsApi = async (page = 1) =>
  Promise.resolve(
    axiosInstance.get(`${endPoints.getAllQuestions}?page=${page}`)
  );

export const deleteQuestionApi = async (id) =>
  Promise.resolve(
    axiosInstance.delete(endPoints.deleteQuestion.replace('{id}', id))
  );

export const editQuestionApi = async (data) =>
  Promise.resolve(axiosInstance.post(endPoints.editQuestion, data));
