import { adminLoginApi } from 'networking/apis/admin';
import { getUserDataApi } from 'networking/apis/user';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { setAxiosSession } from 'utils/auth';
import { Constants } from 'utils/constants';
import { getDataFromToken, isTokenExpired } from 'utils/jwt';

// AUTHENTICATION CONTEXT
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // CONSTANTS
  const { routeNames } = Constants();

  // ROUTING
  const navigate = useNavigate();

  // STATES
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [adminDetails, setAdminDetails] = useState(false);
  const [userData, setUserData] = useState(null);
  const userId = localStorage.getItem('user_id');
  // FUNCTION: Logout
  const logOut = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('adminData');
    setAxiosSession(null);
    setIsLoggedIn(false);
    setAdminData(null);
    setAdminDetails(null);
  }, []);

  // FUNCTION: Initialize Authentication
  const initialize = useCallback(async () => {
    try {
      const loggedIn = JSON.parse(localStorage.getItem('loggedIn')) || false;
      const token = localStorage.getItem('accessToken');
      // Ensure loggedIn and token are present
      if (loggedIn && token && isTokenExpired(token)) {
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);
        setAdminData(tokenData);
        setIsLoggedIn(true);
      } else {
        logOut();
      }

      setIsInitialized(true);
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, [logOut]);

  const getUserData = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await getUserDataApi(userId);
      if (response?.data?.data) {
        setUserData(response.data.data);
      } else {
        setUserData(null);
      }
    } catch (err) {
      setUserData(null);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, [userId, getUserData]);

  useEffect(() => {
    if (userData) {
      if (userData.step_status === 'quiz') {
        navigate(routeNames.quiz);
      } else if (userData.step_status === 'spin') {
        navigate(routeNames.pickYourDorito);
      } else if (userData.step_status === 'winner') {
        navigate(routeNames.prizeClaim);
      } else if (userData.step_status === 'lost') {
        navigate(routeNames.oops);
      } else {
        navigate(routeNames.dashboard);
      }
    } else {
      navigate(routeNames.dashboard);
    }
  }, [userData]);

  // FUNCTION: Admin Login
  const adminLogin = useCallback(
    async (loginData) => {
      setShowLoader(true);
      try {
        const response = await adminLoginApi(loginData);
        const { data, token, message } = response.data;
        if (message === 'success') {
          setAxiosSession(token);
          localStorage.setItem('accessToken', token);
          localStorage.setItem('loggedIn', true);
          localStorage.setItem('adminData', JSON.stringify(data));
          setIsLoggedIn(true);
          setShowLoader(false);
          navigate(routeNames.adminDashboard);
        } else {
          setShowLoader(false);
          setAuthError(message);
        }
      } catch (error) {
        setShowLoader(false);
        setAuthError(error.message);
      }
    },
    [navigate, routeNames.adminDashboard]
  );

  // EFFECT: Initialize Authentication on Component Mount
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (localStorage.getItem('adminData')) {
      setAdminDetails(JSON.parse(localStorage.getItem('adminData')));
    }
  }, [isLoggedIn]);

  // Memoized Context Value
  const memoizedValue = useMemo(
    () => ({
      // VALUES
      isInitialized,
      isLoggedIn,
      adminData,
      authError,
      showLoader,
      adminDetails,
      userData,

      // FUNCTIONS
      setIsInitialized,
      setIsLoggedIn,
      setAdminData,
      setAuthError,
      setShowLoader,
      logOut,
      adminLogin,
      setAdminDetails,
      setUserData,
    }),
    [
      isInitialized,
      isLoggedIn,
      adminData,
      authError,
      showLoader,
      logOut,
      adminLogin,
      adminDetails,
      setAdminDetails,
      userData,
      setUserData,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
